import React, { useState,useEffect } from 'react';
import './tabs.css';
import Energy from "../Energy/energy"
import Progress from '../Progress/progress';
import Emission from '../Emission/emission';
import Water from '../Water/Water';
import Waste from '../Waste/waste'
import Diversity from '../Diversity/Diversity';
import Safety from '../Safety/Safety';
import Training from '../Training/Training';
import Environment from '../Environment/environment';
import TrainingOverview from '../TrainingOverview/trainingOverview';
import TraineeOverview from '../TraineeOverviev/TraineeOverview';
const TabsComponent = ({setCurrentTab,fromDate,keyTab,setKeyTab, toDate,locationOption,timePeriods,financialYearId,graphData,frameworkValue,sectorQuestionAnswerDataForGraph, framework}) => {
    const [ activebtnTab, setactivebtnTab] = useState(0);
    const [permissionList,setPermissionList]=useState([]);
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    useEffect(()=>{
        const dashboardMenu = JSON.parse(localStorage.getItem("menu"));
    const dashboardObject = dashboardMenu.find(
      (item) => item.caption === "Dashboard"
    ).permissions;
    setPermissionList(dashboardObject);
    setactivebtnTab(currentUser.userType === 'TRAINEE' ? 9:0);
    },[])
    const handleTabClick = (index) => {
        setactivebtnTab(index);
    };

    useEffect(()=>{
      setCurrentTab(activebtnTab)
    },[activebtnTab])

    const renderUI = () => {
        switch ( activebtnTab) {
            case 0:
                return (
                    permissionList.some(
                        (permission) =>
                        permission.permissionCode === "STATUS_OVERVIEW" &&
                        permission.checked
                    ) && (
                        <Progress framework={framework} timePeriods = {timePeriods} fromDate={fromDate} toDate={toDate} financialYearId={financialYearId} locationOption = {locationOption}/>
                    )
                );
            case 1:
                return ( 
                    permissionList.some(
                      (permission) =>
                        permission.permissionCode ===
                          "QUESTION_TYPE_PROGRESS" && permission.checked
                    ) && <Environment keyTab={keyTab} locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData}  frameworkValue={frameworkValue} sectorQuestionAnswerDataForGraph={sectorQuestionAnswerDataForGraph}/> );
            case 2:
                return ( permissionList.some(
                    (permission) =>
                      permission.permissionCode ===
                        "QUESTION_TYPE_PROGRESS" && permission.checked
                  ) && (
                    <Energy keyTab={keyTab} locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData}  frameworkValue={frameworkValue} sectorQuestionAnswerDataForGraph={sectorQuestionAnswerDataForGraph}/>
                  ));
            case 3:
                return ( 
                permissionList.some(
                  (permission) =>
                    permission.permissionCode ===
                      "QUESTION_TYPE_PROGRESS" && permission.checked
                ) && <Emission  locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData} frameworkValue={frameworkValue} sectorQuestionAnswerDataForGraph={sectorQuestionAnswerDataForGraph}/> );
            case 4:
                return ( permissionList.some(
                    (permission) =>
                      permission.permissionCode ===
                        "QUESTION_TYPE_PROGRESS" && permission.checked
                  ) && <Water keyTab={keyTab} locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData}  frameworkValue={frameworkValue} sectorQuestionAnswerDataForGraph={sectorQuestionAnswerDataForGraph}/>);
            case 5:
                return (
                    permissionList.some(
                        (permission) =>
                          permission.permissionCode ===
                            "QUESTION_TYPE_PROGRESS" && permission.checked
                      ) && <Waste keyTab={keyTab} locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData} frameworkValue={frameworkValue}/>
                );
            case 6:
                return (
                    permissionList.some(
                        (permission) =>
                          permission.permissionCode ===
                            "QUESTION_TYPE_PROGRESS" && permission.checked
                      ) && <Diversity locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData} frameworkValue={frameworkValue}/>

                );
            case 7:
                return (
                    permissionList.some(
                        (permission) =>
                          permission.permissionCode ===
                            "QUESTION_TYPE_PROGRESS" && permission.checked
                      ) && <Safety locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData} frameworkValue={frameworkValue}/>


                );
                case 8:
                return (
                    permissionList.some(
                        (permission) =>
                          permission.permissionCode ===
                            "QUESTION_TYPE_PROGRESS" && permission.checked
                      ) && <Training locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData} frameworkValue={frameworkValue}/>


                );
                case 9:
                    return (
                        permissionList.some(
                            (permission) =>
                              permission.permissionCode ===
                                "QUESTION_TYPE_PROGRESS" && permission.checked
                          ) && <TrainingOverview locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData} frameworkValue={frameworkValue}/>
    
    
                    );
                    case 10:
                        return (
                            permissionList.some(
                                (permission) =>
                                  permission.permissionCode ===
                                    "QUESTION_TYPE_PROGRESS" && permission.checked
                              ) && <TraineeOverview locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData} frameworkValue={frameworkValue}/>
        
        
                        );
              
            default:
                return null;
        }
    };

    return (
        <>
            <div className="d-flex justify-content-between buttoncont" style={{marginBottom:"25px"}}>
              {currentUser && currentUser.userType === 'TRAINEE' ? <></> : <> <button className={`btn button ${ activebtnTab === 0 ? ' activebtn' : ''}`} onClick={() => handleTabClick(0)}>Progress</button>
                <button className={`btn button ${ activebtnTab === 1 ? ' activebtn' : ''}`} onClick={() => handleTabClick(1)}>Environment</button>
                <button className={`btn button ${ activebtnTab === 2 ? ' activebtn' : ''}`} onClick={() => handleTabClick(2)}>Energy</button>
                <button className={`btn button ${ activebtnTab === 3 ? ' activebtn' : ''}`} onClick={() => handleTabClick(3)}>Emission</button>
                <button className={`btn button ${ activebtnTab === 4 ? ' activebtn' : ''}`} onClick={() => handleTabClick(4)}>Water</button>
                <button className={`btn button ${ activebtnTab === 5 ? ' activebtn' : ''}`} onClick={() => handleTabClick(5)}>Waste</button>
                <button className={`btn button ${ activebtnTab === 6 ? ' activebtn' : ''}`} onClick={() => handleTabClick(6)}>Diversity</button>
                <button className={`btn button ${ activebtnTab === 7 ? ' activebtn' : ''}`} onClick={() => handleTabClick(7)}>Safety</button>
                <button className={`btn button ${ activebtnTab === 8 ? ' activebtn' : ''}`} onClick={() => handleTabClick(8)}>Training</button>
                <button className={`btn button ${ activebtnTab === 9 ? ' activebtn' : ''}`} onClick={() => handleTabClick(9)}>Trainer Overview</button></> 
              }
                <button className={`btn button ${ activebtnTab === 10 ? ' activebtn' : ''}`} onClick={() => handleTabClick(10)}>Trainee Overview</button>

            </div>
            {renderUI()}
        </>
    );
};

export default TabsComponent; 